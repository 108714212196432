@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background: linear-gradient(to right, #171715, #2d2d29);
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bottom-12 {
  bottom: 3rem;
}

.no-scrollbar::-webkit-scrollbar {
  width: 0.5em;
}

.no-scrollbar::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.no-scrollbar {
  scrollbar-width: none;
}